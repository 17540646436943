.container_banner {
  height: 100vh;
  width: 100%;
}

.imagen_lg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.transparencia {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 11;
}

.card_sombra {
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.margen_top {
  margin-top: 100px;
}

main {
  max-width: 100%;
  overflow: hidden;
}

/* para probar el banner */
.each-slide-effect > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 600px;
  width: 100%;
  position: relative;
  /*background: url(../../../assets/images/Banner_Header_LG_pequeño.jpg);*/
}

.each-slide-effect span {
  width: 100%;
  position: absolute;
  font-size: 20px;
  text-transform: uppercase;
  color: #fff;
  padding: 10px 60px;
  background: rgba(0, 27, 159, 0.8);
  bottom: 0;
}

/*Modo Responsivo*/
@media only screen and (min-width: 320px) and (max-width: 992px) {
  .each-slide-effect > div {
    background: url(../../../assets/images/Banner_header_sm.jpg);
    height: 100;
    width: 100%;
    background-size: cover;
  }
}
