@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.logos {
  overflow: hidden;
  padding: 60px 0px;
  white-space: nowrap;
}

.logos-slide {
  display: inline-block;
  animation: 60s slide infinite linear;
}
.logos-slide img {
  height: 65px;
  margin: 0 40px;
}

.imagenes_asociados {
  height: 65px;
}
