.nav_fijo {
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 9998;
}

.seleccionado {
  background-color: rgba(0, 27, 159, 0.8);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.color_boton:hover {
  color: #fff;
  background: rgba(0, 27, 159, 0.8);
  transform: scale(1.1);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

/* Estilos para el multi menu */
.dropdown-item:hover {
  background-color: rgba(0, 27, 159, 0.8);
  color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.dropdown .dropdown-menu {
  display: none;
}
.dropdown:hover > .dropdown-menu,
.dropend:hover > .dropdown-menu {
  display: block;
}
@media screen and (min-width: 769px) {
  .dropend:hover > .dropdown-menu {
    position: absolute;
    top: 0;
    left: 100%;
  }
  .dropend .dropdown-toggle {
    margin-left: 0.5em;
  }
}

/* lo nuevo */
/* header bottom */
.container__menu {
  width: 100%;
  height: 70px;
  background: rgba(0, 27, 159, 0.8);
  padding: 0px 20px;
}

.menu {
  max-width: 1200px;
  margin: auto;
  height: 100%;
}

.menu_scrolled {
  height: 70px;
  background: rgba(0, 27, 159, 1);
}

nav {
  height: 100%;
}

nav > ul {
  height: 100%;
  display: flex;
}

nav ul li {
  height: 100%;
  list-style: none;
  position: relative;
}

nav > ul > li:first-child > a {
  background-image: url("../../../assets/images/Logo.png"); /*('../img/Logo.png');*/
  background-size: 46px;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 20px 40px;
}

nav > ul > li:first-child:hover > a {
  background-image: url("../../../assets/images/Logo.png"); /*('../img/Logo.png');*/
  background-size: 36px;
  background-repeat: no-repeat;
  background-position: center center;
}

nav > ul > li > a {
  width: 100%;
  height: 100%;
  display: flex;
  color: #fff;
  align-items: center;
  padding: 14px;
  text-transform: uppercase;
  font-size: 14px;
  transition: all 300ms ease;
  text-decoration: none;
}

nav > ul > li > a:hover {
  transform: scale(1.1);
  /*background: #0074C7;*/
  background: rgba(254, 85, 0, 1);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

#seleccionado {
  transform: scale(1.1);
  /*background: #0074C7;*/
  background-color: rgba(254, 85, 0, 1);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

/*submenu*/
nav ul li ul {
  width: 350px;
  display: flex;
  flex-direction: column;
  background: #fff;
  position: absolute;
  top: 90px;
  left: -5px;
  padding: 14px 0px;
  visibility: hidden;
  opacity: 0;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  z-index: 10px;
  transition: all 300ms ease;
}

nav ul li:hover ul {
  visibility: visible;
  opacity: 1;
  top: 75px;
}

nav ul li ul:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid white;
  position: absolute;
  top: -12px;
  left: 20px;
}

nav ul li ul li a {
  display: block;
  color: rgba(0, 27, 159, 1); /*#0099E9;*/
  padding: 6px;
  padding-left: 14px;
  margin-top: 10px;
  font-size: 14px;
  text-transform: uppercase;
  transition: all 300ms ease;
  text-decoration: none;
}

nav ul li ul li a:hover {
  background: rgba(254, 85, 0, 1);
  color: #fff;
  transform: scale(1);
  padding-left: 30px;
  font-size: 14px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

/* elemmentos responsivos */
.icon__menu {
  font-size: 26px;
  color: #fff;
  cursor: pointer;
  width: 26px;
  height: 100%;
  display: none;
  align-items: center;
}

#label__check {
  width: 26px;
  height: 100%;
  display: none;
}

#check__menu {
  display: none;
}

/*Menu responsive*/
@media screen and (max-width: 720px) {
  .top-bar {
    align-items: center;
  }

  nav > ul {
    flex-direction: column;
    background-color: rgba(0, 27, 159, 1);
    position: fixed;
    left: 0;
    top: 90px;
    width: 100%;
    height: 0;
    transition: all 300ms ease;
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    padding-left: 0;
  }

  nav ul li ul {
    z-index: 102;
  }

  nav > ul > li > a:hover {
    transform: scale(1);
  }

  nav ul li ul {
    left: 90px;
  }

  nav > ul > li:hover ul {
    top: 50px;
  }

  nav > ul > li:first-child a {
    background-position: 40px;
  }

  #seleccionado {
    transform: scale(1);
  }

  #label__check {
    display: block;
  }

  .icon__menu {
    display: flex;
  }

  #check__menu:checked ~ nav > ul {
    height: 300px;
    visibility: visible;
    opacity: 1;
  }
}
