.footer_background {
  background: rgba(0, 27, 159, 1);
  /*background: -moz-linear-gradient(left, rgba(254,85,0,1) 0%, rgba(254,85,0,1) 3%, rgba(0,27,159,1) 83%, rgba(0,27,159,1) 85%, rgba(0,27,159,1) 86%, rgba(0,26,159,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(254,85,0,1)), color-stop(3%, rgba(254,85,0,1)), color-stop(83%, rgba(0,27,159,1)), color-stop(85%, rgba(0,27,159,1)), color-stop(86%, rgba(0,27,159,1)), color-stop(100%, rgba(0,26,159,1)));
    background: -webkit-linear-gradient(left, rgba(254,85,0,1) 0%, rgba(254,85,0,1) 3%, rgba(0,27,159,1) 83%, rgba(0,27,159,1) 85%, rgba(0,27,159,1) 86%, rgba(0,26,159,1) 100%);
    background: -o-linear-gradient(left, rgba(254,85,0,1) 0%, rgba(254,85,0,1) 3%, rgba(0,27,159,1) 83%, rgba(0,27,159,1) 85%, rgba(0,27,159,1) 86%, rgba(0,26,159,1) 100%);
    background: -ms-linear-gradient(left, rgba(254,85,0,1) 0%, rgba(254,85,0,1) 3%, rgba(0,27,159,1) 83%, rgba(0,27,159,1) 85%, rgba(0,27,159,1) 86%, rgba(0,26,159,1) 100%);
    background: linear-gradient(to right, rgba(254,85,0,1) 0%, rgba(254,85,0,1) 3%, rgba(0,27,159,1) 83%, rgba(0,27,159,1) 85%, rgba(0,27,159,1) 86%, rgba(0,26,159,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fe5500', endColorstr='#001a9f', GradientType=1 );*/
}

.footer_redes {
  background-color: rgba(254, 85, 0, 1);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  width: 100px;
  height: 100px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  color: #001b9f;
}

.footer_redes:hover {
  background-color: rgb(226, 82, 10);
  transform: translateY(4px);
  /*box-shadow: 0px 0px 0px 1px rgb(255, 255, 255);*/
  color: #fff;
}

.scroll-to-top {
  background-color: rgba(254, 85, 0, 1) !important;
}

/* efectos del boton de redes sociales */
.social_reveal {
  position: absolute;
  top: -1.44rem;
  right: 1.88rem;
  visibility: hidden;
  width: auto;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: -webkit-transform 0.35s ease;
  -o-transition: transform 0.35s ease;
  transition: transform 0.35s ease;
  transition: transform 0.35s ease, -webkit-transform 0.35s ease;
}

/* mostramos el boton al hacer click en el */
.social_reveal:active {
  z-index: 11;
  visibility: visible;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transform: translateX(-78px);
  -ms-transform: translateX(-78px);
  transform: translateX(-78px);
  -webkit-transition: -webkit-transform 0.35s ease;
  -o-transition: transform 0.35s ease;
  transition: transform 0.35s ease;
  transition: transform 0.35s ease, -webkit-transform 0.35s ease;
}

.cna{
  width: 450px;
  background-color: #fff;
  height: 80px;
}