@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
* {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.banner_pages {
  height: 600px;
  width: 100%;
}

.banner_nuestro_instituto {
  background-image: url("../../../assets/images/banner_pages.jpg"); /*("../img/banner_pages.jpg");*/
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.titulo {
  color: rgba(254, 85, 0, 1);
}

.subtitulo {
  color: rgba(0, 27, 159, 1);
}

.list-group-item {
  border: none;
}

.justificado {
  text-align: justify !important;
}

.parallax_valores_ae {
  height: 350px;
  background-image: url("../../../assets/images/parrallax_ae_values.jpg"); /*("../img/parrallax_ae_values.jpg");*/
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
}

.parallax_ae {
  height: 300px;
  background-image: url("../../../assets/images/Banner_ae.jpg"); /*("../img/banner_ae.jpg");*/
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
}

/*tamaño de altura carousel slider*/
.owl-carousel .owl-stage-outer {
  height: 450px;
}

.owl-item {
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
}

/* Para hacer zoom a las imagenes de Mision y vision */
.zoom {
  overflow: hidden;
}

.zoom img {
  transition: all 1.2s ease;
}

.img-mision {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  overflow: hidden;
}

/* Para cambiar de color las letras al hacer hover sobre las carreras */
.color_hover:hover {
  color: rgba(254, 85, 0, 1);
  font-weight: bold;
}

/* Parallax para reparar los docentes, debajo de la jefa de la carrera */
.parallax_docentes_rrhh {
  height: 300px;
  background-image: url("../../../assets/images/parallax_docentes.jpg"); /*("../img/parallax_docentes.jpg");*/
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
}

/* parallax para separar los docentes, de la carrera de procesos industriales*/
.parallax_docentes_procesos_industriales {
  height: 300px;
  background-image: url("../../../assets/images/parallax_docentes_procesos_industriales.jpg"); /*("../img/parallax_docentes_procesos_industriales.jpg");*/
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
}

.parallax_docentes_administracion_comercio {
  height: 300px;
  background-image: url("../../../assets/images/parallax_docentes_administracion_comercio.jpg"); /*("../img/parallax_docentes_administracion_comercio.jpg");*/
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
}

.parallax_egresados {
  height: 300px;
  background-image: url("../../../assets/images/parallax_egresados.jpg"); /*("../img/parallax_docentes_administracion_comercio.jpg");*/
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
}

/*Parallax aseguramiento de la calidad*/
.parallax_adc {
  height: 300px;
  background-image: url("../../../assets/images/parallax-aseguramiento-de-la-calidad.jpg"); /*("../img/parallax_docentes_administracion_comercio.jpg");*/
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
}

btn {
  color: rgba(254, 85, 0, 1);
}

.boton_personalizado:hover {
  background-color: rgba(254, 85, 0, 1);
  color: #fff;
  border-radius: 5%;
}

/* Card de Autoridades para section de nuestra gente */
.card_autoridades {
  background: #ebebeb;
  border-radius: 5rem;
  cursor: pointer;
}

.persona_oficina {
  /*background: rgba(254,85,0,1);*/
  border-radius: 10%;
  bottom: 0.5rem;
  color: #fff;
  display: grid;
  font-size: 2rem;
  height: 4rem;
  left: 0.3rem;
  line-height: 3rem;
  place-items: center;
  text-align: center;
  width: 4rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.22);
}

.card__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: -0.5rem;
  width: 85%;
}

.card__info--title {
  color: rgba(0, 27, 159, 1);
}

.color_header_cards {
  background-color: rgba(0, 27, 159, 1);
}

/* Ocultar boton de ir arriba
 ========================================================*/
.ir_arriba {
  display: none;
  padding: 20px;
  background-color: rgba(89, 105, 141, 1);
  font-size: 15px;
  color: #fff;
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 100;
  border-radius: 3px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 2.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.ir_arriba:hover {
  background-color: rgba(115, 136, 181, 1);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.ir_arriba:active {
  transform: scale(0.92);
}

.ir_arriba:scrolldown {
  display: block;
}

/* Adaptado a dispositivos moviles
 =============================================================*/

@media only screen and (min-width: 320px) and (max-width: 992px) {
  .ir_arriba {
    padding: 15px;
    right: 60px;
  }
}

/* Post de nuestra Gente */

.post_nuestra_gente {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 0 0 0.25rem 0.25rem;
}

.parallax_beca_ipla {
  height: 300px;
  background-image: url("../../../assets/images/parallax_beca_ipla.jpg"); /*("../img/parallax_docentes_administracion_comercio.jpg");*/
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
}

/* Responsive desing */
@media screen and (max-width: 720px) {
  .banner_nuestro_instituto {
    background-image: url("../../../assets/images/banner_pages_sm.jpg"); /*("../img/banner_pages.jpg");*/
    width: 100%;
    background-attachment: fixed;
  }
}
