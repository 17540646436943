.preloader{
    height: 100vh;
    width: 100%;
    background: #001b9f;
    color: #fe5500;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.preloader .texts-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 65px;
    width: 1000px;
    font-size: 25px;
    font-weight: 800;
    overflow: hidden;
    color: #fe5500;
    text-transform: uppercase;
}